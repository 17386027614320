import styled from 'styled-components';
import { Card } from 'lib/components/Card/Card';

export const StyledMachineRobotStatus = styled(Card)`
  .ant-card-head {
    padding: 24px;
    padding-bottom: 0%;
    border-bottom: none;
    min-height: unset;
    .ant-card-head-wrapper {
      height: unset;
      .ant-card-head-title {
        h3 {
          ${(props): string => props.theme.fontStyles.redesign.xl.strong}
          font-weight: 700;
          text-transform: capitalize;
          margin: 0;
        }
      }
    }
  }

  .ant-card-body {
    height: 100%;
    padding: 24px;
    padding-top: 20px;
  }

  .machine-robot-status__line {
    border: none;
    background-color: ${(props): string => props.theme.colors.brightGrey};
    margin: 8px 0;
    height: 1px;
    width: 100%;
  }

  .machine-robot-status__cleaning-status,
  .machine-robot-status__routine-wrapper,
  .machine-robot-status__cleaning-router-wrapper,
  .machine-robot-status__progress-wrapper {
    padding-bottom: 16px;
  }

  @media screen and (min-width: 1441px) {
    .machine-robot-status__power-info-wrapper {
      justify-content: space-evenly;
    }
  }
`;
