import { useTranslation } from 'react-i18next';
import { ReactElement } from 'react';
import { StyledMachineRobotPowerInfo } from './MachineRobotPowerInfo.styles';
import { Odometer } from 'lib/components/Odometer/Odometer';

interface MachineRobotStatusPowerInfoProps {
  icon: ReactElement;
  value: string;
  label: string;
  unit: string;
}

export const MachineRobotStatusPowerInfo = ({
  value,
  icon,
  label,
  unit,
}: MachineRobotStatusPowerInfoProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineRobotPowerInfo>
      <div className="machine-robot-power__wrapper">
        <div className="machine-robot-power__icon">{icon}</div>
        <div className="machine-robot-power__data">
          <Odometer value={value} />
          <span className="machine-robot-power__unit" dangerouslySetInnerHTML={{ __html: unit }} />
        </div>
      </div>
      <span className="machine-robot-power__label">{t(label)}</span>
    </StyledMachineRobotPowerInfo>
  );
};
