import { ISvgIconName } from '../../../../lib/components/SvgIcon/interfaces/SvgIconNames';
import {
  Machine as MachineGraphQl,
  MachinesReport,
  MachineTypeByName,
  MachineUpdate,
  MachineVariant,
  Maybe,
  OperatingTimePeriod,
  ResponseFleetOperatingTime,
  ResponseLatestCleaningTaskReport,
  ResponseMachineGet,
  ResponseMachinesExportFile,
  ResponseMachinesList,
  ResponseMachinesListExportRequest,
  ResponseMachinesReportExportRequest,
  ResponseMachinesReportList,
  ResponseMachineTypesList,
  ResponseMachineUpdate,
  ResponseMachineVariantsList,
  ResponseMetadata,
  ResponseRobotCleaningConsumptionSummary,
  Scalars,
  Site,
  State,
  MachineClassification as MachineCategory,
  ResponseMachinesReportSubscriptions,
  ResponseMachineFilter,
  DataStatus,
  ResponseLatestRoutine,
  BatteryDischargeStatus,
  MachineBatteryDischargeStatus,
  MachineGeofenceStatus,
  OperatingTimePlanndAndActualForPeriod,
} from '../../../cross-cutting-concerns/communication/interfaces/am-api-graphql';

// TODO: Remove after all tickets regarding the switch from connection status to
// machine status are implemented
export enum MachineConnectionColors {
  RED = 'red',
  ORANGE = 'orange',
  GREEN = 'green',
}

// TODO: Remove after all tickets regarding the switch from connection status to
// machine status are implemented
export interface IConnectionStatus {
  color: MachineConnectionColors;
  description: string;
  text: string;
}

// TODO: Remove after all tickets regarding the switch from connection status to
// machine status are implemented
export const ConnectionStatuses: IConnectionStatus[] = [
  {
    color: MachineConnectionColors.GREEN,
    description: 'machineConnectionStatus.green.description',
    text: 'machineConnectionStatus.green.text',
  },
  {
    color: MachineConnectionColors.ORANGE,
    description: 'machineConnectionStatus.orange.description',
    text: 'machineConnectionStatus.orange.text',
  },
  {
    color: MachineConnectionColors.RED,
    description: 'machineConnectionStatus.red.description',
    text: 'machineConnectionStatus.red.text',
  },
];

export interface Machine extends Omit<MachineGraphQl, 'variant'> {
  variant?: MachineVariant;
}

export type MachineAsReport = MachinesReport;

export interface MachineVariantDatum {
  id: Scalars['ID'];
  variant: MachineVariant;
}

export interface MachineTelemtryDatum {
  id: Scalars['ID'];
  states?: Maybe<State[]>;
}

export interface MachineLatestCtrDatum {
  id: Scalars['ID'];
  cleaningTaskReportLatestGet?: Maybe<ResponseLatestCleaningTaskReport>;
}

export interface MachineLatestRoutineDatum {
  id: Scalars['ID'];
  latestRoutine?: Maybe<ResponseLatestRoutine>;
}

export enum LegacyMachineStatus {
  DATA_AGE_LESS_THAN_ONE_DAY = 'DATA_AGE_LESS_THAN_ONE_DAY',
  DATA_AGE_ONE_TO_THREE_DAYS = 'DATA_AGE_ONE_TO_THREE_DAYS',
  DATA_AGE_OVER_THREE_DAYS = 'DATA_AGE_OVER_THREE_DAYS',
  NON_IOT = 'NON_IOT',
  DEACTIVATED = 'DEACTIVATED',
  ARCHIVED = 'ARCHIVED',
}

export interface ILegacyMachineStatusItemData {
  status: LegacyMachineStatus;
  icon: ISvgIconName;
  title: string;
  description: string;
}

export const LegacyMachineStatusItemData: { [key: string]: ILegacyMachineStatusItemData } = {
  [LegacyMachineStatus.DATA_AGE_LESS_THAN_ONE_DAY]: {
    status: LegacyMachineStatus.DATA_AGE_LESS_THAN_ONE_DAY,
    // TODO: Update new icon when it's ready
    icon: 'dataAgeLessThanOneDay' as ISvgIconName,
    description: 'machineStatus.active.description',
    title: 'machineStatus.active.title',
  },
  [LegacyMachineStatus.DATA_AGE_ONE_TO_THREE_DAYS]: {
    status: LegacyMachineStatus.DATA_AGE_ONE_TO_THREE_DAYS,
    icon: 'dataAgeOneToThreeDays' as ISvgIconName,
    description: 'machineStatus.dataAgeOneToThreeDays.description',
    title: 'machineStatus.dataAgeOneToThreeDays.title',
  },
  [LegacyMachineStatus.DATA_AGE_OVER_THREE_DAYS]: {
    status: LegacyMachineStatus.DATA_AGE_OVER_THREE_DAYS,
    icon: 'dataAgeOverThreeDays' as ISvgIconName,
    description: 'machineStatus.dataAgeOverThreeDays.description',
    title: 'machineStatus.dataAgeOverThreeDays.title',
  },
  [LegacyMachineStatus.NON_IOT]: {
    status: LegacyMachineStatus.NON_IOT,
    icon: 'nonIot' as ISvgIconName,
    description: 'machineStatus.nonIot.description',
    title: 'machineStatus.nonIot.title',
  },
  [LegacyMachineStatus.DEACTIVATED]: {
    status: LegacyMachineStatus.DEACTIVATED,
    icon: 'deactivated' as ISvgIconName,
    description: 'machineStatus.deactivated.description',
    title: 'machineStatus.deactivated.title',
  },
};

export enum MachineStatus {
  DATA_UP_TO_DATE = 'DATA_UP_TO_DATE',
  DATA_AGE_ONE_TO_THREE_DAYS = 'DATA_AGE_ONE_TO_THREE_DAYS',
  DATA_AGE_OVER_THREE_DAYS = 'DATA_AGE_OVER_THREE_DAYS',
  NO_DATA = 'NO_DATA',
}

export interface IMachineStatusItemData {
  status: DataStatus;
  icon: ISvgIconName;
  title: string;
  description: string;
  color: string;
}

export const MachineStatusItemData: { [key: string]: IMachineStatusItemData } = {
  [DataStatus.LessThanOneDayOld]: {
    status: DataStatus.LessThanOneDayOld,
    icon: 'checkCircle' as ISvgIconName,
    description: 'machineStatus.active.description',
    title: 'machineStatus.active.title',
    color: 'green',
  },
  [DataStatus.OneToThreeDaysOld]: {
    status: DataStatus.OneToThreeDaysOld,
    icon: 'dataAgeOneToThreeDays' as ISvgIconName,
    description: 'machineStatus.dataAgeOneToThreeDays.description',
    title: 'machineStatus.dataAgeOneToThreeDays.title',
    color: 'yellow',
  },
  [DataStatus.GreaterThanThreeDaysOld]: {
    status: DataStatus.GreaterThanThreeDaysOld,
    icon: 'warning' as ISvgIconName,
    description: 'machineStatus.dataAgeOverThreeDays.description',
    title: 'machineStatus.dataAgeOverThreeDays.title',
    color: 'red',
  },
  [DataStatus.NoData]: {
    status: DataStatus.NoData,
    icon: 'notAvailable' as ISvgIconName,
    description: 'machineStatus.noData.description',
    title: 'machineStatus.noData.title',
    color: 'gray',
  },
};

export interface IMachineClassificationItemData {
  classification: 'IOT' | 'NON_IOT';
  icon: ISvgIconName;
  title: string;
}

export const MachineClassificationItemData: { [key: string]: IMachineClassificationItemData } = {
  IOT: {
    classification: 'IOT',
    icon: 'iotNew' as ISvgIconName,
    title: 'common.iot',
  },
  NON_IOT: {
    classification: 'NON_IOT',
    icon: 'nonIotNew' as ISvgIconName,
    title: 'common.nonIot',
  },
};

export interface IMachineCategoryItemData {
  category: MachineCategory.Gcd | MachineCategory.Robot;
  icon: ISvgIconName;
  title: string;
}

export const MachineCategoryItemData: { [key: string]: IMachineCategoryItemData } = {
  [MachineCategory.Gcd]: {
    category: MachineCategory.Gcd,
    icon: 'machine' as ISvgIconName,
    title: 'common.machines',
  },
  [MachineCategory.Robot]: {
    category: MachineCategory.Robot,
    icon: 'robots' as ISvgIconName,
    title: 'common.robots',
  },
};

export interface MachineListAvailableFilters {
  machineTypes: MachineTypeByName[];
  sites: Site[];
  machineStatus: ILegacyMachineStatusItemData[];
}

export interface MachineList {
  machines: ResponseMachinesList;
}

export interface MachineListAsReport {
  machinesReport: ResponseMachinesReportList;
}

export interface MachineListVariantData {
  machines: {
    data: MachineVariantDatum[];
    metadata?: Maybe<ResponseMetadata>;
  };
}

export interface MachineListTelemetryData {
  machines: {
    data: MachineTelemtryDatum[];
    metadata?: Maybe<ResponseMetadata>;
  };
}

export interface MachineListLatestCtrData {
  machines: {
    data: MachineLatestCtrDatum[];
    metadata?: Maybe<ResponseMetadata>;
  };
}

export interface MachineListLatestRoutineData {
  machines: {
    data: MachineLatestRoutineDatum[];
    metadata?: Maybe<ResponseMetadata>;
  };
}

export interface MachinesAvailableToBeAssigned {
  machines: ResponseMachinesList;
}

export interface MachinesAvailableToBeAssignedVariantData {
  machines: {
    __typename?: 'ResponseMachinesList';
    data: MachineVariantDatum[];
  };
}

export interface MachineDetails {
  machine: ResponseMachineGet;
}

export interface MachineDetailsVariantDatum {
  machine: {
    __typename?: 'ResponseMachineGet';
    data?: Maybe<MachineVariantDatum>;
  };
}

export interface MachineDetailsTelemetriesDatum {
  machine: {
    __typename?: 'ResponseMachineGet';
    data?: Maybe<MachineTelemtryDatum>;
  };
}

export interface MachineDetailsLatestCtrDatum {
  machine: {
    __typename?: 'ResponseMachineGet';
    data?: Maybe<MachineLatestCtrDatum>;
  };
}

export interface MachineDetailsLatestRoutineDatum {
  machine: {
    __typename?: 'ResponseMachineGet';
    data?: Maybe<MachineLatestRoutineDatum>;
  };
}

export interface MachineVariantsResponse {
  machineVariants: ResponseMachineVariantsList;
}

export interface MachineUpdateResponse {
  machineUpdate: ResponseMachineUpdate;
}

export type MachineWithCleaningReport = Omit<Machine, 'operatingTimeForPeriod'> & {
  operatingTimeForPeriod: OperatingTimePeriod;
};

export interface GetMachineOperatingTimeForPeriod {
  machine: {
    data: {
      operatingTimeForPeriod: OperatingTimePeriod;
    };
  };
}

export interface MachinesListWithCleaningReport {
  machines: Omit<ResponseMachinesList, 'data'> & {
    data: MachineWithCleaningReport[];
  };
}

export type MachineAvailableToFilter = Pick<Machine, 'id' | 'name' | 'type' | 'classification'>;

export interface MachinesAvailableToFilter {
  machines: Omit<ResponseMachinesList, 'data' | 'metadata'> & {
    data: MachineAvailableToFilter[];
  };
}

export interface MachineTypesResponse {
  machineTypes: ResponseMachineTypesList;
}

export interface FleetOperatingTimeResponse {
  fleetOperatingTime: ResponseFleetOperatingTime;
}

export interface MachinesExportRequest {
  machinesListExportRequest: ResponseMachinesListExportRequest;
}

export interface MachinesReportExportRequest {
  machinesReportExportRequest: ResponseMachinesReportExportRequest;
}

export interface MachinesExportGetFile {
  machinesExportFile: ResponseMachinesExportFile;
}

export interface IMachineClassificationAvailabilityConfig {
  hasAccessToRobots: boolean;
  hasAccessToGCD: boolean;
}

export interface MachineRobotCleaningConsumptionSummaryResponse {
  robotConsumptionSummary: ResponseRobotCleaningConsumptionSummary;
}

export interface SubscriptionMachineUpdateResult {
  onMachineUpdate: MachineUpdate;
}

export interface MachineReportSubscriptionsListResponse {
  machinesReportSubscriptionsList: ResponseMachinesReportSubscriptions;
}

export interface MachineReportSubscriptionsResetResponse {
  machinesReportSubscriptionsReset: ResponseMachinesReportSubscriptions;
}
export { MachineCategory };

export interface MachineFilterResponse {
  machineFilter: ResponseMachineFilter;
}

export enum BatteryDischargeStatusUnAvailable {
  UnAvailable = 'UNAVAILABLE',
}

export type BatteryDischargeStatusWithUnAvailable =
  | BatteryDischargeStatus
  | MachineBatteryDischargeStatus
  | BatteryDischargeStatusUnAvailable;

export interface IBatteryDischargeStatus {
  status: BatteryDischargeStatusWithUnAvailable;
  icon: ISvgIconName;
  title: string;
  description?: string;
  color: string;
  shortText: string;
}

export const BatteryDischargeStatusItemData: { [key: string]: IBatteryDischargeStatus } = {
  [BatteryDischargeStatus.Ok]: {
    status: BatteryDischargeStatus.Ok,
    icon: 'checkmark' as ISvgIconName,
    title: 'machineDetails.batteryVoltage.ok.title',
    color: 'green',
    shortText: 'machineDetails.batteryVoltage.ok.shortText',
  },
  [BatteryDischargeStatus.Warning]: {
    status: BatteryDischargeStatus.Warning,
    icon: 'warning' as ISvgIconName,
    description: 'machineDetails.batteryVoltage.warning.description',
    title: 'machineDetails.batteryVoltage.warning.title',
    color: 'yellow',
    shortText: 'machineDetails.batteryVoltage.warning.shortText',
  },
  [BatteryDischargeStatus.Critical]: {
    status: BatteryDischargeStatus.Critical,
    icon: 'warningInformationCritical' as ISvgIconName,
    description: 'machineDetails.batteryVoltage.critical.description',
    title: 'machineDetails.batteryVoltage.critical.title',
    color: 'red',
    shortText: 'machineDetails.batteryVoltage.critical.shortText',
  },
  [BatteryDischargeStatusUnAvailable.UnAvailable]: {
    status: BatteryDischargeStatusUnAvailable.UnAvailable,
    icon: 'minusCircle' as ISvgIconName,
    title: 'machineDetails.batteryVoltage.unavailable.title',
    color: 'gray',
    shortText: 'machineDetails.batteryVoltage.unavailable.shortText',
  },
};

export const GeofenceStatus = {
  Inside: MachineGeofenceStatus.Inside,
  Outside: MachineGeofenceStatus.Outside,
  NoData: 'NODATA',
} as const;

export type GeofenceStatus = (typeof GeofenceStatus)[keyof typeof GeofenceStatus];

export interface IMachineGeofenceStatusItemData {
  status: MachineGeofenceStatus.Inside | MachineGeofenceStatus.Outside | '';
  icon: ISvgIconName;
  color: string;
  title: string;
}

export const MachineGeofenceStatusItemData: { [key: string]: IMachineGeofenceStatusItemData } = {
  [GeofenceStatus.Inside]: {
    status: MachineGeofenceStatus.Inside,
    icon: 'checkCircle' as ISvgIconName,
    color: 'green',
    title: 'common.inside',
  },
  [GeofenceStatus.Outside]: {
    status: MachineGeofenceStatus.Outside,
    icon: 'warning' as ISvgIconName,
    color: 'red',
    title: 'common.outside',
  },
  [GeofenceStatus.NoData]: {
    status: '',
    icon: 'minusCircle' as ISvgIconName,
    color: 'gray',
    title: 'common.noGeofence',
  },
};

export interface GetMachinePlannedAndActualTimeForPeriod {
  data: OperatingTimePlanndAndActualForPeriod;
}
